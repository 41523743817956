<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">物业公司列表</div>
        <el-button type="primary" @click="createCompany" class="cardTitleBtn">新建物业公司</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="companyList" style="width: 100%">
                <el-table-column prop="name" label="公司名" sortable></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="primary"
                      size="mini"
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
   <!-- <el-col :span="24">
          <div class="block" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="companyList.nowPageNum"
              :page-sizes="[ 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="companyList.totalNum"
            >
            </el-pagination>
          </div>
        </el-col> -->
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      companyList:[],
      pageSize: 20,
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getCompanyList()
  },
  methods: {
    getCompanyList() {
      let self = this;
      self.$axios
        .get("/company/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          console.log(response);
          self.companyList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "用户列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "CompanyDetail", query: { guid: row.guid } });
    },
    createCompany() {
      let self = this;
      self.$router.push({ name: "CompanyDetail", query: { guid: null } });
    },
     handleSizeChange(val) {
      this.pageSize = val * 1;
      this.getUserPageNum()
    },
    handleCurrentChange(val) {
      this.getUserPageNum(val)
    },
    getUserPageNum(val) {
      this.getCompanyList(val);
    },
  },
};
</script>

<style scoped>
</style>
